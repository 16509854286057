<template>
  <div class="">
    <!--Filters-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <b-row no-gutters class="w-100">
          <b-col>
            <h5>{{ msg('Filters') }}</h5>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-link style="color: inherit" @click="resetFilters">
                <feather-icon icon="XIcon" size="16"/>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>

          <b-col cols="12" md="3" class="mb-md-0">
            <label>{{ msg('Client') }}</label>
            <b-form-input
                :debounce="500"
                v-model="filter.like.fullName"
                class="d-inline-block mr-1"
            />
          </b-col>

          <b-col cols="12" md="3" class="mb-md-0">
            <label>{{ msg('Status') }}</label>
            <v-select
                :dir="isRTL ? 'rtl' : 'ltr'"
                v-model="filter.in.status"
                :options="orderStatus"
                class="w-100"
                label="text"
                :reduce="(status) => status.value"
                multiple
            />
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0">
            <label>{{ msg("Contract") }} {{ msg('Id') }}</label>
            <b-form-input
                v-model="filter.like.dynamicId"
                class="d-inline-block mr-1"
            />
          </b-col>

          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <b-form-group :label="msg('Platform')" label-for="realEstate-branch">
              <v-select
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  v-model="filter.eq.platform"
                  :options="[
                      {value:'Booking',label:('Booking')},
                      {value:'Airbnb',label:('Airbnb')},
                      {value:'Treasure Home',label:('Treasure Home')},
                      {value:'Expedia',label:('Expedia')},
                      {value:'Vrbo',label:('Vrbo')},
                      {value:'Agoda',label:('Agoda')},
                      {value:null,label:('All')}
                      ]"
                  :clearable="false"

                  label="label"
                  :reduce="(status) => status.value"
                  class="w-100"

              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3" class="mt-1">

            <b-form-group :label="msg('Real estate')" label-for="realEstate-branch">
              <real-estate-picker v-model="filter.eq.dynamicId2"/>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3" class="mt-1">
            <label>{{ msg('Invoice#') }}</label>
            <b-form-input
                :debounce="500"
                v-model="filter.like.invoiceNumber"
                class="d-inline-block mr-1"
            />
          </b-col>
          <b-col cols="12" md="6" class="mt-1">
            <b-form-group :label="msg('checkinrange')" label-for="realEstate-branch">

              <calendar-picker-range v-model="filter.eq.datesRange"/>
            </b-form-group>
          </b-col>


          <b-col cols="12" md="3">
            <div class="mt-2">
              <b-form-group :description="msg('Local tax paid') ">

                <b-form-checkbox
                    v-model="filter.eq.paidTax"
                    name="check-button" switch>
                </b-form-checkbox>
              </b-form-group>

            </div>

          </b-col>

        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <div class="card-header" style="overflow: hidden">
        <span class="card-title m-0">{{ msg('Invoices') }}</span>
        <b-col>
          <div class="d-flex justify-content-end">
            <b-link style="color: inherit" @click="()=>$refs['invoiceTable'].refresh()">
              <feather-icon icon="RefreshCcwIcon" size="16"/>
            </b-link>
          </div>
        </b-col>
      </div>
      <!--Card Content-->
      <b-card-body>
        <div class="">
          <!-- Table Top -->
          <b-row no-gutters class="mb-1">
            <!-- Per Page -->
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>{{ msg('Show') }}</label>
              <v-select
                  v-model="amountPerPage"
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :options="[10, 25, 50, 100,500,1000]"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                  @input="(amount)=>$refs['invoiceTable'].updatePageAmount(amount)"
              />
              <label>{{ msg('entries') }}</label>
            </b-col>
            <b-col cols="12" md="12" class="d-flex justify-content-md-end">
              <b-button variant="primary" class="mt-0 mb-2" @click="onTreasureContractGenerateMulti()">
                <span class="text-nowrap">{{ msg('generatetreasurerentmulti') }}</span>
              </b-button>
              <b-button variant="primary" class="mt-0 mb-2 ml-1" @click="openSalesOverview()">
                <span class="text-nowrap">{{ msg('profitlook') }}</span>
              </b-button>
            </b-col>


            <!-- Search -->
            <b-col cols="12" md="12" class="d-flex justify-content-md-end">
              <div class="">
                <span style="font-weight: bold">{{ msg('Total Brutto') }} </span>
                <span style="font-weight: bold;color: #00acc1">{{ convertToEURO(totalAmount) }}</span>
                <span class="mr-1"></span>
              </div>
            </b-col>
            <b-col cols="12" md="12" class="d-flex justify-content-md-end">
              <div class="">
                <span style="font-weight: bold">{{ msg('tax10') }} </span>
                <span style="font-weight: bold;color: #00acc1">{{ convertToEURO(tax) }}</span>
                <span class="mr-1"></span>
              </div>
            </b-col>
            <!-- Search -->
            <b-col cols="12" md="12" class="d-flex justify-content-md-end">
              <div class="">
                <span style="font-weight: bold">{{ msg('totallocaltax') }} </span>
                <span style="font-weight: bold;color: #00acc1">{{ convertToEURO(totalTax) }}</span>
                <span class="mr-1"></span>
              </div>
            </b-col>
            <!-- Search -->

            <b-col cols="12" md="6" class="mt-2 d-flex justify-content-md-start">
              <b-button variant="secondary" size="sm"
                        :disabled="selectedMonetaryIds==null||selectedMonetaryIds.length<1"
                        @click="makeMonetaryVisible" class="btn btn-primary">
                {{ msg('visiblemonetarybycheckoutdate') }}
              </b-button>
            </b-col>

            <b-col cols="12" md="6" class="mt-2 d-flex justify-content-md-end">
              <b-button variant="primary" size="sm" :disabled="selectedMonetaryIds==null||selectedMonetaryIds.length<1"
                        @click="openTotalLocalTaxConfirmation" class="btn btn-primary">
                {{ msg('payselectedtax') }}
              </b-button>
            </b-col>


          </b-row>
          <invoices-table ref="invoiceTable" v-bind:filter="filter" @custom-event-name="handleDataFromChild"/>

          <b-modal :id="taxTotalModal.id" :title="msg('Confirmation')" @ok="payTotalTax"
                   ok-variant="danger" :cancel-title="msg('cancel')" @hide="resettotalTaxModal">
            <p class="my-4">{{ msg('confirmtaxpayment') }}</p>
          </b-modal>
        </div>
      </b-card-body>
    </b-card>
    <b-modal modal-class="modal-fullscreen" no-fade v-model="treasureContractMulti" size="xl"
             ref="realEstate-modal"
             id="realEstate-modal"
             :hide-footer="true" no-close-on-backdrop centered>
      <treasure-contract-rent-panel-multi ref="realEstatePanel" @submitted="onSubmitted"/>
      <div class="text-center" style="justify-content:center">
        <b-button :disabled="savingContract" variant="outline-success" class="btn-dialog mr-1" @click="submitContract">
          {{ savingContract ? msg('Loading') : msg('Save') }}
        </b-button>
        <b-button :disabled="!savingContract" variant="outline-danger" class="btn-dialog" @click="resetButton">
          {{ msg('Reset') }}
        </b-button>
      </div>
    </b-modal>
    <b-modal modal-class="modal-fullscreen" no-fade v-model="salesOverview" size="md"
             :hide-footer="true" no-close-on-backdrop centered>
      <div class="text-center" style="justify-content:center">

        <b-col cols="12" md="12" class="mt-1">
          <b-form-group :label="msg('checkinrange')" label-for="realEstate-branch">

            <calendar-picker-range v-model="datesRangeExcel"/>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="12" class="mb-md-0 mb-2">
          <b-form-group :label="msg('Platform')" label-for="realEstate-branch">
            <v-select
                :dir="isRTL ? 'rtl' : 'ltr'"
                v-model="excelPlatform"
                :options="[
                      {value:'Booking',label:('Booking')},
                      {value:'Airbnb',label:('Airbnb')},
                      {value:'Treasure Home',label:('Treasure Home')},
                      {value:'Expedia',label:('Expedia')},
                      {value:'Vrbo',label:('Vrbo')},
                      {value:'Agoda',label:('Agoda')},
                      {value:null,label:('All')}
                      ]"
                :clearable="false"

                label="label"
                :reduce="(status) => status.value"
                class="w-100"

            />
          </b-form-group>
        </b-col>

        <b-col v-if="buildings!=null && buildings.length>0" cols="12" md="12"
               class="mr-1 mb-2 ">
          <b-form-group :label="msg('Building')" label-for="realEstate-branch">

          <v-select
              placeholder="Building"
              :dir="isRTL ? 'rtl' : 'ltr'"
              v-model="buildingExcel"
              :options="buildings"
              class="w-100"
              label="address"
              :reduce="(building) => building.id"
          />
          </b-form-group>
        </b-col>

        <b-button :disabled="generatingExcel || buildingExcel==null || datesRangeExcel==null||datesRangeExcel.start==null || datesRangeExcel.end==null" variant="outline-success"
                  class="btn-dialog mr-1" @click="generateExcel">
          {{ generatingExcel ? msg('Loading') : msg('Generate Excel') }}
        </b-button>

      </div>
    </b-modal>

  </div>

</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {mapActions, mapGetters} from 'vuex'
import InvoicesTable from '@/views/monetary/invoice/invoices-table'
import CalendarPickerRange from "@/components/widgets/calendar/calendarPickerRange";
import RealEstatePicker from "@/components/components/picker/realEstatesPicker";
import mixinFilter from "@/mixins/mixinFilter";
import TreasureContractRentPanelMulti from "@/views/realEstate/contracts/treasureContractRentPanelMulti.vue";

const invoiceFilter = () => ({
  fromDate: null,
  untilDate: null,
  in: {status: ["PAID"]},
  like: {id: "", amount: ""},
  gte: {creationDate: null,},
  eq: {},

  lte: {creationDate: null}
});

const buildingFilter = () => ({
  like: {},
  eq: {},
  lte: {},
  gte: {},
  in: {}
})

export default {
  components: {
    TreasureContractRentPanelMulti,
    /* eslint-disable-next-line vue/no-unused-components */
    InvoicesTable, CalendarPickerRange, RealEstatePicker, BCardActions
  },
  mixins: [mixinFilter],
  name: 'invoices',
  data: () => ({
    datesRange: null,
    datesRangeExcel: null,
    filter: null,
    buildingFilter: buildingFilter(),
    selectedMonetaryIds: null,
    savingContract: false,
    treasureContractMulti: false,
    salesOverview: false,
    buildings: [],
    buildingExcel: null,
    generatingExcel: null,
    excelPlatform: null,
    taxTotalModal: {
      id: 'tax-total-modal',
      title: '',
      content: ''
    },
    totalAmount: 0.0,
    totalTax: 0.0,
    tax: 0.0,
    amountPerPage: 10,
    items: [],
  }),
  watch: {
    filter: {
      deep: true,
      handler() {
      }
    },
  },

  created() {
    this.filter = this.loadCachedFilters(invoiceFilter);

    let $this = this;
    this.getAllBuildings({
      page: 1,
      amount: 9999,
      filter: $this.buildingFilter,
      sortcolumn: 'updatedDate',
      sortby: 'desc',
    }).then(this.onBuildingsRetrieve, (error) => $this.error = error)
  },
  computed: {
    ...mapGetters('order', ['allMonetaryStatus']),
    orderStatus() {
      return this.allMonetaryStatus.map(status => ({
        value: status.value,
        text: this.msg(status.text).toUpperCase()
      }))
    },
    creationDate: {
      get() {
        return {start: this.filter.fromDate, end: this.filter.untilDate}
      },
      set(v) {
        this.filter.fromDate = v.start;
        this.filter.untilDate = v.end;
      }
    }
  },
  methods: {
    ...mapActions('monetary', ['payMultiMonetaryTax', 'makeMultiMonetaryVisible', 'generateExcelOverview']),
    ...mapActions('building', ['getAllBuildings']),
    generateExcel() {
      this.generatingExcel = true;
      let start=this.datesRangeExcel.start;
      let end=this.datesRangeExcel.end;
      if (this.excelPlatform==='null')
        this.excelPlatform=null;
      this.generateExcelOverview({platform: this.excelPlatform, buildingId: this.buildingExcel,start:start,end:end}).then(
          (url) => {
            window.open(url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
            this.generatingExcel = false;
          }
      );
    },
    resettotalTaxModal() {
      this.$root.$emit('bv::hide::modal', this.taxTotalModal.id)
    },
    onSubmitted(ans) {
      if (ans.status) {
        this.refresh()

        this.dialogs.treasureContract = false;
        this.dialogs.treasureContractMulti = false;

      }

    },
    submitContract() {
      this.savingContract = true;
      this.$root.$emit('crm::submitContract')
    },
    resetButton() {
      this.savingContract = false;
    },
    payTotalTax() {
      let msghis = this
      this.payMultiMonetaryTax({ids: this.selectedMonetaryIds})
          .then(() => {
                if (msghis.$refs.invoiceTable) {
                  msghis.$refs.invoiceTable.refresh(); // Call the child component's refresh method
                }

              }
          )
    },
    onTreasureContractGenerateMulti() {
      this.treasureContractMulti = true
    },
    openSalesOverview() {
      let $this = this;

      this.salesOverview = true
    },
    onBuildingsRetrieve(data) {
      this.buildings = data["Buildings"];
    },
    openTotalLocalTaxConfirmation() {
      this.$root.$emit('bv::show::modal', this.taxTotalModal.id)
    },
    makeMonetaryVisible() {
      let msghis = this
      this.makeMultiMonetaryVisible()
          .then(() => {
                if (msghis.$refs.invoiceTable) {
                  msghis.$refs.invoiceTable.refresh(); // Call the child component's refresh method
                }

              }
          )
    },
    handleDataFromChild(data) {
      console.log("data: ", data);
      this.selectedMonetaryIds = data['key']
          .filter(invoice => invoice.paidTax === null || !invoice.paidTax)
          .map(invoice => invoice.id);

      const totalAmount = data['key']
          .filter(invoice => (invoice.paidTax === null || !invoice.paidTax) && invoice.system_comment == null)
          .reduce((sum, invoice) => sum + invoice.amount, 0);

      console.log("totalAmount: ", totalAmount);
      this.totalAmount = totalAmount;
      this.totalTax = totalAmount * 0.025237;
      this.tax = totalAmount - (totalAmount / 1.1);
    },
    convertToEURO(someNumber) {
      return new Intl.NumberFormat("de-AT", {
        style: "currency",
        currency: "EUR",
      }).format(someNumber);
    },
    rangeToStr(dateRange) {
      if (dateRange.start == null)
        return "";
      if (dateRange.end == null)
        return this.$moment(dateRange.start, 'YYYY-MM-DD').format('DD/MM/YYYY');
      return this.$moment(dateRange.start, 'YYYY-MM-DD').format('DD/MM/YYYY') + " - " + this.$moment(dateRange.end, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }
  }
}
</script>

<style scoped>

</style>
